<template>
  <div>
    <v-container>
      <v-row class="mt-4 justify-center">
        <v-col cols="12" md="6" class="d-flex justify-center">
            <v-img
              max-width="80%"
              @click="goToNarbulut()"
              src="https://blade.narbulut.com/images/narbulut-logo-full.png"
            ></v-img>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div class="body-1 d-flex align-center">
            <v-icon left color="success">mdi-check-circle</v-icon>Sayın
            {{ $store.state.guest.name }} Başarıyla Kayıt Oldunuz
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goToNarbulut()
    {
        window.location.href = 'https://narbulut.com'
    }
  },
};
</script>
<style lang="" scoped></style>
