import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import RegisterSuccess from "../views/RegisterSuccess.vue";
import store from "../store";

// Admin

import Login from "../views/Admin/Login.vue";
import AdminHome from "../views/Admin/AdminHome.vue";
import Draw from "../views/Admin/Draw.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      appBarVisible: false,
    },
    component: Home,
  },
  {
    path: "/register/success",
    name: "RegisterSuccess",
    meta: {
      appBarVisible: false,
    },
    component: RegisterSuccess,
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      appBarVisible: false,
    },
    component: Login,
  },
  {
    path: "/admin",
    name: "AdminHome",
    meta: {
      appBarVisible: true,
    },
    component: AdminHome,
    beforeEnter: (to, from, next) => {
      if (store.state.token) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/draw",
    name: "Draw",
    meta: {
      appBarVisible: true,
    },
    component: Draw,
    beforeEnter: (to, from, next) => {
      if (store.state.token) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/:id",
    name: "HomeWithDraw",
    meta: {
      appBarVisible: false,
    },
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
