<template>
  <v-app>
    <v-app-bar
      app
      v-if="
        $route.meta.appBarVisible != null ? $route.meta.appBarVisible : true
      "
    >
      <div class="d-flex align-center justify-space-between" style="width:100%">
        <v-img
          v-for="(image, index) in getImagesByVuex"
          :key="'image-' + index"
          :alt="image.image.name"
          class="shrink mr-6"
          contain
          :src="image.image.url"
          transition="scale-transition"
          :width="image.image.width ?? 200"
          :height="image.image.height ?? null"
        />
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import axios from "./axios/index";

export default {
  name: "App",

  data: () => ({
    images: [],
    draw:null,
    //
  }),
  computed:{
    getImagesByVuex(){
      return this.$store.state.images ?? [];
    }
  },
  methods: {
    getActiveDraws() {

      let url = "/pub/active/draw";
      if(this.$route.params.id)
      {
        url += '/' + this.$route.params.id;
      }

      let loading = this.$store.state.loading;
      loading.draw = true;
      this.$store.commit('SET_LOADING', loading);
      axios.get(url).then((res) => {
        console.log(res);
        this.images = res.data.images;
        this.draw = res.data.draw;
        this.draw.started = res.data.started;
        
        this.$store.commit('SET_QUESTION', res.data.question);
        this.$store.commit('SET_DRAW', this.draw);
        this.$store.commit('SET_IMAGES', this.images);
      });
    },
  },
  created() {
    this.getActiveDraws();
  },
};
</script>
