<template>
  <div>
    <div v-if="loading">
      <v-row class="mt-4 justify-center">
        <v-col cols="12" md="6" class="d-flex justify-center">
          <v-img
            max-width="80%"
            src="https://blade.narbulut.com/images/narbulut-logo-full.png"
          ></v-img>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="!loading && draw?.started == true">
      <v-row class="mt-4 justify-center">
        <v-col cols="12" md="6" class="d-flex justify-center">
          <v-img
            max-width="80%"
            src="https://blade.narbulut.com/images/narbulut-logo-full.png"
          ></v-img>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div class="body-1">
           <b>{{ draw.name }}</b> 
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div class="text-center subtitle-2">
            <v-icon>mdi-minus-circle</v-icon><br />
            Üzgünüz, çekiliş başladığından dolayı katılamazsınız.
          </div>
        </v-col>
      </v-row>
    </div>

    <v-form
      ref="form"
      v-model="validRegister"
      lazy-validation
      v-if="!loading && draw?.started == false"
    >
      <v-row class="mt-4 justify-center">
        <v-col cols="12" md="6" class="d-flex justify-center">
          <v-img
            max-width="80%"
            src="https://blade.narbulut.com/images/narbulut-logo-full.png"
          ></v-img>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div><b>{{ draw.name }}</b></div>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            label="İsim Soyisim"
            v-model="register.name"
            :rules="RulesMustBe"
            @input="register.name = String(register.name).toUpperCase()"
          ></v-text-field>
          <v-text-field
            outlined
            label="Email"
            v-model="register.email"
            type="email"
            :rules="RulesEmail"
          ></v-text-field>
          <vue-tel-input-vuetify
            @validate="phoneValidated"
            v-model="register.phone"
            :rules="[
              ...RulesMustBe,
              phoneValidate || 'Hatalı Telefon Numarası',
            ]"
            outlined
            placeholder="5xx"
            :onlyCountries="['TR']"
            label="Telefon Numarası"
            required
            mode="international"
          ></vue-tel-input-vuetify>
          <v-text-field
            outlined
            label="Firma Adı"
            v-model="register.company"
            :rules="RulesMustBe"
          ></v-text-field>
          <v-text-field
            outlined
            label="Firmadaki Görevi"
            v-model="register.position"
            :rules="RulesMustBe"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="">
          {{ getQuestion.name }}
          <div
            v-for="(option, index) in getQuestion?.options"
            :key="'questionOption-' + index"
          >
            <div v-if="String(option.inputType).toLowerCase() == 'checkbox'">
              <v-checkbox
              hide-details
                v-model="register.questionAnswers"
                :label="option.text"
                :value="option.id"
              ></v-checkbox>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-textarea
          hide-details
          outlined
          name="input-7-4"
          label="Açık Rıza Metni"
          readonly
          value="6698 Sayılı Kişisel Verilerin Korunması Kanunu(KVKK) ilgili hükümlerine uygun olarak; Narbulut Bilgi Teknolojileri Sanayi Ve Ticaret Anonim Şirketi – NAR BULUT tarafından, KVKK kapsamında, tarafıma gerekli bilgilendirmenin yapıldığını, kişisel verilerin, işlenmesi, aktarılması, toplanması gibi hususlarda, “KVKK AYDINLATMA METNİ”’ni okuduğumu, anladığımı, onayladığımı ve bu metinde belirtilen amaçlarla sınırlı olmak kaydıyla, kişisel ve özel nitelikli kişisel verilerimin, kaydedilmesini, kullanılmasını ve aktarılmasını, özgeçmiş kontrolü yapılmasını, işe yatkınlığım konusunda referanslarımdan bilgi alınmasını, kanunda ön görülen sürelere ve şekillere uygun olarak işlenmesini, herhangi bir etki altında kalmaksızın onayladığımı ve açık bir şekilde rıza gösterdiğimi kabul, beyan ve taahhüt ederim."
        ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-checkbox v-model="kvkkAgree" hide-details>
            <template v-slot:label>
        <div>
          Açık Rıza Metni ve 

              <a
                target="_blank"
                href="https://www.narbulut.com/kvkk-aydinlatma-metni/"
                @click.stop
              >
                KVKK Aydınlatma Metni</a>
          'ni okudum, kabul ediyorum
        </div>
      </template>
    </v-checkbox>
        </v-col>
        <v-col md="12" cols="6" class="d-flex justify-center">
          <v-btn
            color="success"
            width="200"
            :loading="loadingRegister"
            @click="registerRequest"
            :disabled="loadingRegister || !(kvkkAgree)"
            ><v-icon left>mdi-account-plus</v-icon>Kayıt Ol</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { RulesMustBe, RulesEmail } from "../utils/rules";
import axios from "../axios";
export default {
  name: "",
  components: {},
  mixins: [RulesMustBe, RulesEmail],
  props: {},
  data() {
    return {
      phoneValidate: false,
      validRegister: true,
      loadingRegister: false,
      kvkkAgree:true,
      //  draw: null,

      register: {
        name: null,
        email: null,
        phone: null,
        company: null,
        position: null,
        questionAnswers: [],
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading.draw;
    },
    draw() {
      return this.$store.state.currentDraw;
    },
    getQuestion() {
      return this.$store.state.currentQuestion;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    getActiveDraw() {
      console.log(this.$store.state);

      //this.draw = this.$store.state.currentDraw;
      this.loadingDraw = this.$store.state.loadingDraw;

      /*
      axios
        .get("/public/active/draw")
        .then((res) => {
          this.draw = res.data.draw;
          this.started = res.data.started;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Beklenmedik bir hata oluştu, tekrar deneyiniz.");
        }); */
    },
    phoneValidated({ isValid }) {
      this.phoneValidate = isValid;
    },
    registerRequest() {
      let validated = this.$refs.form.validate();
      if (validated && this.phoneValidate && this.draw != null) {
        let payload = {
          drawId: this.draw.id,
          guest: this.register,
        };

        this.loadingRegister = true;
        axios
          .post(`/register/${this.draw.id}`, this.register)
          .then(() => {
            this.$store.commit("SET_GUEST", payload);
            this.$router.push({ name: "RegisterSuccess" });
          })
          .catch((err) => {
            this.$toast.error(err.response.data.message);
            if (err.response.data.code == "already_register") {
              payload.guest = err.response.data.user
                ? err.response.data.user
                : payload.guest;
              this.$store.commit("SET_GUEST", payload);
              this.$router.push({ name: "RegisterSuccess" });
            }
          })
          .finally(() => {
            this.loadingRegister = false;
          });
      }
    },
  },
  created() {
    let isRegistered = this.$store.getters.guestIsRegistered;
    console.log(isRegistered);

    if (isRegistered) {
      this.$router.push({ name: "RegisterSuccess" });
    }
    this.getActiveDraw();
  },
};
</script>
<style lang="css">
.country-code {
  display: none;
}

.v-btn {
  text-transform: none;
}
</style>
