<template>
  <div>
    <v-dialog v-model="dialogWinnerList">
      <v-card>
        <v-card-title>
          <download-excel
            :data="currentWinners"
            :fields="winnerFields"
            :name="currentDraw?.name + ' Kazanan Listesi'"
            :header="'Soru: ' + currentDraw?.question?.name"
          >
            Excel'e Aktar
          </download-excel>
        </v-card-title>
        <v-data-table
          :headers="headersWinner"
          :items="currentWinners"
          item-key="id"
        >
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogGuestList">
      <v-card>
        <v-card-title>
          <download-excel
            :data="currentGuests"
            :fields="guestFields"
            :name="currentDraw?.name + ' Katılımcı Listesi'"
            :header="'Soru: ' + currentDraw?.question?.name"
          >
            Excel'e Aktar
          </download-excel>
        </v-card-title>
        <v-data-table
          :headers="headersGuest"
          :items="currentGuests"
          item-key="id"
        >
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-data-table
      :items="draws"
      :headers="headers"
      item-key="id"
      show-expand
      :loading="loadingList"
    >
      <template v-slot:expanded-item="{ headers, item }" style="width: 100%">
        <td :colspan="headers.length">
          <v-container>
            <v-row style="width: 100%">
              <v-col cols="12" md="3">
                <v-btn color="primary" @click="getGuestList(item)"
                  >Katılımcı Listesi</v-btn
                >
              </v-col>
              <v-col cols="12" md="3">
                <v-btn color="primary" @click="getWinnerList(item)"
                  >Kazanan Listesi</v-btn
                >
              </v-col>
              <v-col cols="12" md="3" :disabled="true">
                <v-btn color="success" @click="sendMailDraw(item)">Mail Gönder</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      draws: [],
      loadingList: true,
      dialogWinnerList: false,
      dialogGuestList: false,
      currentDraw: null,
      currentWinners: [],
      currentGuests: [],
      headers: [
        {
          text: "Çekiliş No",
          align: "start",
          sortable: true,
          value: "id",
          width: "15",
        },
        {
          text: "Çekiliş Adı",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      headersWinner: [
        {
          text: "Adı Soyadı",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Telefon No",
          align: "start",
          sortable: true,
          value: "phone",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Şirket",
          align: "start",
          sortable: true,
          value: "company",
        },
        {
          text: "Pozisyon",
          align: "start",
          sortable: true,
          value: "position",
        },
        {
          text: "Cevap",
          align: "start",
          sortable: true,
          value: "answer",
        },
        {
          text: "Kazandığı Ürün",
          align: "start",
          sortable: true,
          value: "productName",
        },
      ],
      headersGuest: [
        {
          text: "Adı Soyadı",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Telefon No",
          align: "start",
          sortable: true,
          value: "phone",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Şirket",
          align: "start",
          sortable: true,
          value: "company",
        },
        {
          text: "Pozisyon",
          align: "start",
          sortable: true,
          value: "position",
        },
        {
          text: "Cevap",
          align: "start",
          sortable: true,
          value: "answer",
        },
      ],
    };
  },
  computed: {
    winnerFields() {
      let returnObject = {};
      this.headersWinner.map((header) => {
        const { text, value } = header;
        returnObject[text] = value;
      });

      return returnObject;
    },
    guestFields() {
      let returnObject = {};
      this.headersGuest.map((header) => {
        const { text, value } = header;
        returnObject[text] = value;
      });

      return returnObject;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    sendMailDraw(draw)
    {
      console.log(draw);
    },
    getGuestList(draw) {
      this.$axios.get("/draws/" + draw.id).then((res) => {
        this.currentDraw = res.data.data;

        let guests = this.currentDraw.guests;
        let options = this.currentDraw.question.options;

        this.currentGuests = [];

        guests.map((guest) => {
          const { name, phone, email, company, position, answers, id } = guest;

          let answerArray = [];
          answers.map((answer) => {
            let questionOption = options.find(
              (opt) => opt.id == answer.questionOptionId
            );

            if (questionOption) {
              const { inputType, text } = questionOption;

              if (String(inputType).toLowerCase() == "checkbox") {
                answerArray.push(text);
              }
            }
          });

          let answer = answerArray.join(", ");

          this.currentGuests.push({
            name,
            phone,
            email,
            company,
            position,
            answer,
            id,
          });
        });

        this.dialogGuestList = true;
      });
    },
    getWinnerList(draw) {
      this.$axios.get("/draws/" + draw.id).then((res) => {
        this.currentDraw = res.data.data;
        let winners = this.currentDraw.winners;
        let guests = this.currentDraw.guests;
        let options = this.currentDraw.question.options;
        let products = this.currentDraw.products;

        this.currentWinners = [];

        winners.map((winner) => {

          let guest = guests.find((guest) => guest.id == winner.guestId);
          let { name, phone, email, company, position, answers, id } = guest;
          let product = products.find((product) => product.id == winner.productId);
          const { name: productName } = product;

  
          let answerArray = [];
          answers.map((answer) => {
            let questionOption = options.find(
              (opt) => opt.id == answer.questionOptionId
            );

            if (questionOption) {
              const { inputType, text } = questionOption;

              if (String(inputType).toLowerCase() == "checkbox") {
                answerArray.push(text);
              }
            }
          });

          let answer = answerArray.join(", ");

          this.currentWinners.push({
            name,
            phone,
            email,
            company,
            position,
            answer,
            productName,
            id,
          });
        });

        this.dialogWinnerList = true;
      });
    },
  },
  created() {
    this.loadingList = true;
    this.$axios
      .get("/draws")
      .then((res) => {
        this.draws = res.data.data;
      })
      .catch((err) => {
        console.log(err);
        this.$toast.error(
          err?.response?.data?.message || "Beklenmedik bir hata oluştu"
        );
      })
      .finally(() => {
        this.loadingList = false;
      });
  },
};
</script>
<style lang="" scoped></style>
