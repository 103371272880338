<template>
  <div>
    <FortuneWheel
      style="padding-bottom: 0px; margin-bottom: -7px"
      :style="{ width: qrwidth + 'px' }"
      :canvas="canvasOptions"
      :prizes="reColorPrizes"
      @rotateStart="onCanvasRotateStart"
      @rotateEnd="onRotateEnd"
      :useWeight="true"
      :key="reColorPrizes.length"
    />
  </div>
</template>
<script>
// https://github.com/XiaoLin1995/vue-fortune-wheel/tree/vue2
// https://xiaolin1995.github.io/vue-fortune-wheel/demo/
import FortuneWheel from "vue-fortune-wheel";
import "vue-fortune-wheel/lib/vue-fortune-wheel.css";

export default {
  name: "",
  components: {
    FortuneWheel,
  },
  mixins: [],
  props: ["products", "qrwidth"],
  data() {
    return {
      canvasVerify: true, // Whether the turntable in canvas mode is enabled for verification
      canvasOptions: {
        borderWidth: 6,
        borderColor: "#584b43",
        textDirection: "vertical",
        btnText: "",
        fontSize: 14,
        textLength: 30,
        textRadius: 240,
      },
      prizes: [],
      lastSelectedIndex: null,
    };
  },
  computed: {
    widthSize() {
      console.log(this.qrwidth);
      return this.qrwidth;
    },
    reColorPrizes() {
      let localPrizes = this.prizes;

      const backgroundColors = ["#cdb4db", "#ffc8dd", "#ffafcc", "#bde0fe"];

      localPrizes.map((prize, index) => {
        prize.bgColor = backgroundColors[index % 4];

        prize.color = "white";
      });
      return localPrizes;
    },
  },
  watch: {
    products(val) {
      console.log(val);
    },
  },
  mounted() {},
  methods: {
    onCanvasRotateStart(rotate) {
      this.$emit("rotateStart");
      if (this.canvasVerify) {
        const verified = true; // true: the test passed the verification, false: the test failed the verification
        this.DoServiceVerify(verified, 2000).then((verifiedRes) => {
          if (verifiedRes) {
            console.log("Verification passed, start to rotate");
            try {
              rotate(); // Call the callback, start spinning
            } catch (err) {
              console.log(err);
            }
            this.canvasVerify = false; // Turn off verification mode
          } else {
            alert("Failed verification");
          }
        });
        return;
      }
      console.log("onCanvasRotateStart");
    },
    onRotateEnd(prize) {
      this.$emit("rotateEnd", prize);

      let index = this.prizes.findLastIndex((pr) => pr.value == prize.value);
      if (index > -1) {
        this.lastSelectedIndex = index;
        setTimeout(() => {
          this.prizes.splice(index, 1);

          if (this.prizes.length == 0) {
            this.$emit("finishPrize");
          }
        }, 500);

        //
      }
    },
    // Simulate the request back-end interface, verified: whether to pass the verification, duration: delay time
    DoServiceVerify(verified, duration) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(verified);
        }, duration);
      });
    },
  },
  created() {
    if (this.products.length > 0) {
      let colorIndex = 0;
      let localPrizes = [];

      this.products.map((product) => {
        let totalQuantity = product.quantity;
        console.log(product);

        for (let i = 1; i <= totalQuantity; i++) {
          let prize = {
            id: colorIndex,
            name: product.name,
            value: product.id,
            weight: product.weight,
          };
          colorIndex += 1;
          localPrizes.push(prize);
          console.log('prize', prize);
        }
      });

      /*
      let probability = parseFloat(100/localPrizes.length);

      localPrizes.map((prize) => {
        prize.probability = probability;
      });
 */
      this.$emit("prizeLength", localPrizes.length);
      console.log(localPrizes);
      this.prizes = localPrizes;
    
    }
    console.log("create olduk - spinner");
  },
};
</script>
<style lang="" scoped></style>
