<template>
  <div>
    <v-form ref="form" v-model="validRegister" lazy-validation>
      <v-row class="mt-4 justify-center">
        <v-col cols="12" md="6" class="d-flex justify-center">
          <v-img
            max-width="80%"
            src="https://blade.narbulut.com/images/narbulut-logo-full.png"
          ></v-img>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            label="Email"
            v-model="login.email"
            type="email"
            :rules="RulesEmail"
          ></v-text-field>
          <v-text-field
            outlined
            label="Parola"
            v-model="login.password"
            type="password"
            :rules="RulesPassword"
          ></v-text-field>
        </v-col>
        <v-col md="12" cols="6" class="d-flex justify-center">
          <v-btn
            color="success"
            width="200"
            :loading="loadingRegister"
            @click="loginRequest"
            :disabled="loadingRegister"
            ><v-icon left>mdi-account</v-icon>Giriş Yap</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { RulesMustBe, RulesEmail, RulesPassword } from "../../utils/rules";
import axios from "../../axios";
export default {
  name: "",
  components: {},
  mixins: [RulesMustBe, RulesEmail, RulesPassword],
  props: {},
  data() {
    return {
      phoneValidate: false,
      validRegister: true,
      loadingRegister: false,
      draw: null,

      login: {
        email: null,
        password: null,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    loginRequest() {
      let validated = this.$refs.form.validate();
      if (validated) {
        axios.post("/login", this.login).then((res) => {
            let user = res.data.data.user;
            let token = res.data.data.token;


            this.$store.commit("SET_USER", {user, token});
            this.$router.push({name:'AdminHome'});
        })
        .catch((err) => {
            this.$toast.error(err.response.data.message ?? 'Beklenmedik bir hata oluştu');
        })
        //login
      }
    },
  },
  created() {
    /*
    let isRegistered = this.$store.getters.guestIsRegistered;
    console.log(isRegistered);

    if (isRegistered) {
      this.$router.push({ name: "RegisterSuccess" });
    }
    this.getActiveDraw(); */
  },
};
</script>
<style lang="css">
.country-code {
  display: none;
}

.v-btn {
  text-transform: none;
}
</style>
