<template>
  <div style="height: 100%" class="d-flex">
    <div>
      <v-navigation-drawer permanent expand-on-hover>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">Ayarlar </v-list-item-title>
            <v-list-item-subtitle> Navigasyon </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav v-model="selectedNavigate">
          <v-list-item-group v-model="selectedNavigate" mandatory>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :value="item.component"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
    <div style="width: 100%">
      <v-container fluid>
        <component :is="selectedNavigate"></component>
      </v-container>
    </div>
  </div>
</template>
<script>
import Draws from "./Draws.vue";
import Logout from "./Logout.vue";
import AppBarSet from "./AppBarSet.vue";
export default {
  name: "Profile",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      selectedNavigate: null,
      items: [
        { title: "Çekiliş Bilgileri", icon: "mdi-list-box", component: Draws },
        {
          title: "Logo Ayarları",
          icon: "mdi-image-area",
          component: AppBarSet,
        },

        { title: "Çıkış Yap", icon: "mdi-logout", component: Logout },
      ],
      right: null,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="" scoped></style>
