import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import store from './store';


import axiosIns from "./axios";

// https://github.com/Maronato/vue-toastification/tree/main
import Toast, { POSITION }  from 'vue-toastification';
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
});

Vue.config.productionTip = false

// https://github.com/alexandermendes/vue-confetti
import VueConfetti from 'vue-confetti';
Vue.use(VueConfetti);

Vue.use(VueTelInputVuetify, { vuetify });
Vue.prototype.$axios = axiosIns;

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);


new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
