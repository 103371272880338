<template>
  <div>
    <v-dialog
      v-model="selectDrawDialog"
      :overlay-opacity="0.9"
      transition="dialog-top-transition"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>Çekiliş Seçimi</v-card-title>
        <v-card-text>
          {{ selectedDrawId }}
          <v-select
            :items="allDraws"
            item-value="id"
            item-text="name"
            v-model="selectedDrawId"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="
              {
                getDrawInformation();
                selectDrawDialog = false;
              }
            "
            :disabled="!(selectedDrawId > 0)"
            ><v-icon left>mdi-arrow-right</v-icon>Devam Et</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="winnerGuestProductDialog"
      :overlay-opacity="0.1"
      transition="dialog-top-transition"
      max-width="80%"
    >
      <v-card class="py-12 d-flex align-center flex-column text-h3">
        <div>
          Tebrikler <b>{{ winner?.name }}!</b>
        </div>
        <v-img :src="require('@/assets/confetti.gif')"></v-img>

        <div>
          <b>{{ winner?.product?.name }}</b> Kazandınız
        </div>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn text @click="startedDraw"
          ><v-icon left color="success">mdi-play</v-icon>Çekilişi Başlat</v-btn
        >
      </v-col>
    </v-row>
    <v-container
      fluid
      style="max-height: calc(89vh)"
      class="align-center d-flex"
    >
      <v-row>
        <v-col cols="6" class="d-flex justify-center align-center">
          <v-card
            elevation="0"
            rounded="pill"
            class="d-flex justify-center align-center"
          >
            <v-overlay
              :absolute="true"
              v-if="showCountdownOverlay"
              style="background-color: #45ace9"
              opacity="0"
            >
              <div class="d-flex justify-center align-center">
                <div style="font-size: 300px">{{ countdown }}</div>
              </div>
            </v-overlay>
            <v-overlay :absolute="true" :value="showPrizeOverlay">
              <v-card color="primary">
                <v-card-title class="d-flex justify-center">
                  {{ prize?.name }}
                </v-card-title>
              </v-card>
            </v-overlay>
            <SpinnerWheel
              v-if="visibleLeftSide == leftSideEnum.Wheel"
              :products="products"
              @rotateStart="spinnerRotateStart"
              @rotateEnd="spinnerRotateEnd"
              @finishPrize="finishPrize"
              @prizeLength="(count) => (wheelPrizesLength = count)"
              :qrwidth="zoomQR"
            ></SpinnerWheel>
          </v-card>
          <div v-if="visibleLeftSide == leftSideEnum.QR">
            <qrcode-vue :value="getDrawUrl" :size="zoomQR" level="H" />
            <v-slider
              v-model="zoomQR"
              max="700"
              min="250"
              append-icon="mdi-magnify-plus-outline"
              prepend-icon="mdi-magnify-minus-outline"
            ></v-slider>
          </div>
          <div
            v-if="visibleLeftSide == leftSideEnum.Winners"
            style="widht: 100%"
          >
            <div class="subtitle-2 font-weight-light text-left mr-2">
              Kazanan Sayısı: {{ winners.length }}
            </div>
            <v-data-table
              :items="winners"
              :headers="winnerHeaders"
              height="80vh"
              :items-per-page="-1"
            ></v-data-table>
          </div> </v-col
        ><v-col cols="6">
          <div class="subtitle-2 font-weight-light text-right mr-2">
            Katılımcı Sayısı: {{ guests.length }}
          </div>
          <v-data-table
            height="80vh"
            :loading="!drawIsStarted"
            :headers="guestHeaders"
            :items="guests"
            hide-default-footer
            item-key="id"
            :items-per-page="-1"
            v-model="selectedGuest"
            :single-select="true"
            :item-class="dataTableItemClass"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import SpinnerWheel from "../../components/SpinnerWheel.vue";
import QrcodeVue from "qrcode.vue";

export default {
  name: "",
  components: {
    SpinnerWheel,
    QrcodeVue,
  },
  mixins: [],
  props: {},
  data() {
    return {
      audioSpinFast: null,
      audioSpinSlow: null,
      audioCrowd: null,
      wheelPrizesLength: null,

      allDraws: [],
      selectedDrawId: null,
      selectDrawDialog: false,
      selectDrawImagesCommit: false,

      winnerGuestProductDialog: false,
      spinnerIsRotating: false,
      guestSelectorIsWorking: false,
      wheelButtonElement: null,
      mainCountdown: 2,
      countdown: 2,
      prize: null,
      prizesIsFinish: false,
      winner: null,
      showPrizeOverlay: false,
      showCountdownOverlay: false,
      zoomQR: 300,
      drawIsStarted: false,
      currentDraw: null,
      selectedGuest: [],
      guests: [],
      winners: [],
      products: [],
      DataTableIntervalId: null,
      DataTableIntervalMax: 8000,
      DataTableIntervalOpt: 200,
      DataTableIntervalCurrent: 0,
      guestsLoading: true,
      leftSideEnum: {
        QR: "qr",
        Wheel: "wheel",
        Countdown: "countdown",
        Winners: "winners",
      },
      visibleLeftSide: "qr",
      guestHeaders: [
        {
          text: "İsim Soyisim",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Firma",
          align: "start",
          sortable: true,
          value: "company",
        },
      ],
      winnerHeaders: [
        {
          text: "İsim Soyisim",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Kazanılan Ürün",
          align: "start",
          sortable: true,
          value: "product.name",
        },
      ],
    };
  },
  computed: {
    getDrawUrl() {
      if (this.currentDraw) {
        return window.location.hostname + "/" + this.currentDraw.id;
      }
      return window.location.hostname;
    },
  },
  watch: {},
  mounted() {
    this.audioSpinFast = new Audio(require("@/assets/sounds/wheelfast.mp3"));
    this.audioSpinSlow = new Audio(require("@/assets/sounds/wheelslow.mp3"));
    this.audioCrowd = new Audio(require("@/assets/sounds/crowd.mp3"));

    window.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    showWinners() {
      this.winnerGuestProductDialog = false;
      this.showPrizeOverlay = false;
      this.showCountdownOverlay = false;
      this.visibleLeftSide = this.leftSideEnum.Winners;
      setTimeout(() => {
        this.$confetti.stop();
      }, 2000);
    },
    finishPrize() {
      console.log("finish prize");
      this.prizesIsFinish = true;
    },
    handleKeyDown(event) {
      console.log(event.key);
      if (event.key == "ArrowLeft" && !this.prizesIsFinish) {
        this.startedDraw();
      }

      if (event.key == "ArrowLeft" && this.prizesIsFinish) {
        this.showWinners();
      }
    },

    dataTableItemClass(item) {
      if (item.winner) {
        return "success white--text";
      }
    },
    startCountdown() {
      if (this.countdown <= 0) {
        setTimeout(() => {
          this.showCountdownOverlay = false;
          this.countdown = this.mainCountdown;
          this.wheelButtonElement.click();
        }, 1000);

        return;
      }

      setTimeout(() => {
        this.countdown -= 1;
        this.startCountdown();
      }, 1000);
    },
    startRandomSelectorDataTable() {
      console.log("çalıştı - startrandomselector");
      this.guestSelectorIsWorking = true;

      let unWinnerGuests = this.guests.filter((guest) => guest.winner == false);
      let randomIndex = Math.floor(Math.random() * unWinnerGuests.length);
      let selectedWinnerGuest = unWinnerGuests[randomIndex];
      let selectedWinnerIndex = randomIndex;
      let selectedWinnerIndexInGuests = this.guests.findIndex(
        (guest) => guest.id == selectedWinnerGuest.id
      );
      console.log(selectedWinnerGuest);
      let currentIndex = 0;
      let maxIndex = unWinnerGuests.length - 1;

      this.DataTableIntervalId = setInterval(() => {
        if (this.DataTableIntervalCurrent >= this.DataTableIntervalMax) {
          if (selectedWinnerIndex == currentIndex) {
            this.selectedGuest = [selectedWinnerGuest];
            setTimeout(() => {
              let selectedGuestElement1 = document.querySelector(
                ".v-data-table__selected"
              );
              selectedGuestElement1.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }, 100);

            this.$confetti.start();

            selectedWinnerGuest.winner = true;
            selectedWinnerGuest.product = this.prize;
            this.winner = selectedWinnerGuest;
            console.log("SPLICE-", selectedWinnerIndex, selectedWinnerGuest);
            this.guests.splice(
              selectedWinnerIndexInGuests,
              1,
              selectedWinnerGuest
            );

            clearInterval(this.DataTableIntervalId);
            this.DataTableIntervalCurrent = 0;

            setTimeout(() => {
              this.guestSelectorIsWorking = false;
              this.winnerGuestProductDialog = true;
              this.audioCrowd.play();
            }, 1000);

            let winnerRequest = {
              guestId: selectedWinnerGuest.id,
              productId: this.prize.value,
            };

            this.winners.push(selectedWinnerGuest);

            this.$axios
              .post(`/draws/${this.currentDraw.id}/addwinner`, winnerRequest)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
                this.$toast.error("!");
              });

            /*
            setTimeout(() => {
              this.$confetti.stop();
            }, 5000); */

            return;
          }
        }

        this.selectedGuest = [unWinnerGuests[currentIndex]];
        setTimeout(() => {
          let selectedGuestElement1 = document.querySelector(
            ".v-data-table__selected"
          );
          selectedGuestElement1.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 100);

        currentIndex += 1;

        if (currentIndex > maxIndex) {
          currentIndex = 0;
        }

        this.DataTableIntervalCurrent += this.DataTableIntervalOpt;
      }, this.DataTableIntervalOpt);
    },
    stopRandomSelectorDataTable() {},
    spinnerRotateStart() {
      this.audioSpinSlow.play();

      for (let i = 0; i < 4000; i += 1000) {
        setTimeout(() => {
          this.audioSpinSlow.currentTime = 0;
        }, i);
      }

      this.spinnerIsRotating = true;
      this.startRandomSelectorDataTable();
      this.showPrizeOverlay = false;
      this.showCountdownOverlay = false;
    },
    spinnerRotateEnd(prize) {
      console.log("bitti", prize);
      this.prize = prize;
      this.showPrizeOverlay = true;
      /*
      let winnerGuestIndex = this.guests.findIndex(
        (guest) => guest.id == this.selectedGuest[0].id
      );
      if (winnerGuestIndex > -1) {
        let winnerGuest = this.guests[winnerGuestIndex];
        winnerGuest.winner = true;
        winnerGuest.product = prize;
        this.guests.splice(winnerGuestIndex, 1, winnerGuest);
      } */
      this.spinnerIsRotating = false;
    },
    startedDraw() {
      if (this.spinnerIsRotating || this.guestSelectorIsWorking) {
        return;
      }
      this.$confetti.stop();
      this.winnerGuestProductDialog = false;
      this.showPrizeOverlay = false;
      this.prize = null;
      this.winner = null;
      this.drawIsStarted = true;
      this.visibleLeftSide = this.leftSideEnum.Wheel;
      this.showCountdownOverlay = true;

      setTimeout(() => {
        if (this.wheelPrizesLength == 0) {
          console.log("Prize bitik");
          this.showWinners();
          return;
        } else {
          this.startCountdown();
        }
      }, 500);

      setTimeout(() => {
        const wheelButton = document.querySelector(".fw-btn__btn");

        if (wheelButton) {
          this.wheelButtonElement = wheelButton;
          // wheelButton.click();
        }
      }, 500);
    },
    getDrawInformation() {
      if (this.drawIsStarted) {
        console.log("Draw başladı");
        return;
      }

      this.$axios
        .get("/draws/" + this.selectedDrawId)
        .then((res) => {
          this.winners = [];
          this.currentDraw = res.data.data;
          let guestWithWinnerKey = res.data.data.guests;
          let winnerFromDb = res.data.data.winners;
          this.products = res.data.data.products;

          guestWithWinnerKey.map((guest) => {
            let guestIsExistWinnerFromDb = winnerFromDb.find(
              (winnerInfo) => winnerInfo.guestId == guest.id
            );
            if (guestIsExistWinnerFromDb) {
              guest.winner = true;
              this.products.map((product) => {
                console.log();
                if (product.id == guestIsExistWinnerFromDb.productId) {
                  console.log("product", product);
                  product.quantity -= 1;
                }
              });
              this.winners.push(guest);
            } else {
              guest.winner = false;
            }
          });
          this.guests = guestWithWinnerKey;
          console.log(this.products);

          this.mainCountdown = res.data.data.countdown ?? 10;
          this.countdown = res.data.data.countdown ?? 10;
          if (this.selectDrawImagesCommit != true) {
            console.log("SET IMAGES");
            this.$store.commit("SET_IMAGES", res.data.data.drawImages);
            this.selectDrawImagesCommit = true;
          }
        })
        .finally(() => {
          if (!this.drawIsStarted) {
            setTimeout(() => {
              this.getDrawInformation();
            }, 5000);
          }
        });
    },

    getAllDraws() {
      this.$axios.get("/draws?include=false").then((res) => {
        this.allDraws = res.data.data;
        this.selectDrawDialog = true;
        console.log(res);
      });
    },
  },
  created() {
    // this.getDrawInformation();
    this.getAllDraws();
  },
};
</script>
<style lang="css">
tr.v-data-table__selected {
  background-color: #45ace9 !important;
  color: white;
  transition: background-color 0.5s ease;
}

.v-overlay__content {
  width: 80%;
}
</style>
