import axios from "axios";
import store from '../store';
import router from '../router';
const axiosInstance = axios.create({
  baseURL: "https://draw.diverstreetfood.com",
  withCredentials: false
});

axiosInstance.interceptors.response.use(response => response,
  error => {
    if(error.response.status == 403)
    {
      router.push({name:'Login'})
    }
  });

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = Object.assign(
      {
        Authorization: `Bearer ${store.state.token}`,
        'Access-Control-Allow-Origin': '*'
      },
      config.headers
    );

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
