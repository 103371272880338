<template>
  <div>
    <v-row>
      <AddQuestion
        :visible-modal="addQuestionModalVisible"
        @visibleChange="(val) => (addQuestionModalVisible = val)"
        @newQuestion="addedNewQuestion"
      ></AddQuestion>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field label="Çekiliş Adı" v-model="draw.name"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Çekiliş Süresi ( Saniye )"
          v-model="draw.countdown"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-center">
        <v-select
          :items="questions"
          item-text="name"
          item-value="id"
          v-model="draw.questionId"
          label="Çekiliş Sorusu"
          clearable
          :messages="selectedQuestionAnswers"
        ></v-select>
        <v-btn icon @click="addQuestionModalVisible = true"
          ><v-icon>mdi-plus-circle</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text> Hediyeler </v-card-text>
          <v-card-text>
            <div
              v-for="(product, index) in products"
              :key="`products-${index}`"
              class="d-flex align-center"
            >
              <v-btn icon color="error" @click="deleteProduct(index)"
                ><v-icon>mdi-minus-circle</v-icon></v-btn
              >
              <v-text-field
                label="Ürün"
                style="width: 200"
                v-model="product.name"
                class="mr-1"
              ></v-text-field>
              <v-text-field
                label="Miktar"
                style="width: 30"
                v-model="product.quantity"
              ></v-text-field>
            </div>
          </v-card-text>
          <v-card-text class="d-flex align-center">
            <v-btn icon color="success" @click="addNewProduct"
              ><v-icon>mdi-plus-circle</v-icon></v-btn
            >
            <v-text-field
              label="Ürün Adı"
              style="width: 200"
              ref="newProductName"
              v-model="newProduct.name"
              class="mr-2"
              @keyup.enter="$refs.newProductQuantity.focus()"
            ></v-text-field>
            <v-text-field
              label="Miktar"
              ref="newProductQuantity"
              style="width: 30"
              v-model="newProduct.quantity"
              @keyup.enter="addNewProduct()"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>Mail Listesi</v-card-text>
          <v-card-text>
            <div
              v-for="(mail, index) in mails"
              :key="`mails-${index}`"
              class="d-flex align-center"
            >
              <v-btn icon color="error" @click="deleteMail(index)"
                ><v-icon>mdi-minus-circle</v-icon></v-btn
              >
              <v-text-field
                label="İsim Soyisim"
                style="width: 200"
                v-model="mail.name"
                ref="mailName"
                class="mr-1"
              ></v-text-field>
              <v-text-field
                label="Email"
                type="email"
                style="width: 30"
                ref="mailMail"
                v-model="mail.mail"
              ></v-text-field>
            </div>
          </v-card-text>
          <v-card-text class="d-flex align-center">
            <v-btn icon color="success" @click="addNewMail"
              ><v-icon>mdi-plus-circle</v-icon></v-btn
            >
            <v-text-field
              label="İsim Soyisim"
              style="width: 200"
              v-model="newMail.name"
              ref="newMailName"
              class="mr-1"
              @keyup.enter="$refs.newMailMail.focus()"
            ></v-text-field>
            <v-text-field
              label="Email"
              type="email"
              style="width: 30"
              ref="newMailMail"
              @keyup.enter="addNewMail"
              v-model="newMail.mail"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-app-bar>
          <div class="d-flex align-center">
            <v-img
              v-for="(image, index) in getSelectedImagesObject"
              :key="'image-' + index"
              class="shrink mr-6"
              contain
              :src="image.url"
              transition="scale-transition"
              :width="image.width ?? 200"
              :height="null"
            />
          </div>
        </v-app-bar>
        <v-autocomplete
          v-model="selectedImages"
          :items="images"
          filled
          chips
          color="blue-grey lighten-2"
          label="Logo Seçimi"
          item-text="name"
          item-value="id"
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removeSelectedImage(data.item)"
            >
              <v-avatar left>
                <v-img :src="data.item.url" contain></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <v-img :src="data.item.url" contain></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          color="primary"
          @click="drawRequest"
          :loading="drawRequestLoading"
          :disabled="!(String(draw.name).length > 0 && products.length > 0)"
          ><v-icon left>mdi-content-save</v-icon> Çekilişi Kaydet</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AddQuestion from "./AddQuestion.vue";
export default {
  name: "",
  components: { AddQuestion },
  mixins: [],
  props: {},
  data() {
    return {
      addQuestionModalVisible: false,
      newProduct: {
        name: null,
        quantity: 1,
      },
      newMail: {
        name: null,
        mail: null,
      },
      mails: [],
      products: [],
      questions: [],
      images: [],
      selectedImages: [],
      drawRequestLoading: false,
      draw: {
        name: null,
        questionId: null,
        countdown: 3,
      },
    };
  },
  computed: {
    selectedQuestionAnswers(){
      let questionId = this.draw.questionId;

      let findQuestion = this.questions.find((question) => question.id == questionId);

      if(!findQuestion)
      {
        return '';
      }

      let options = findQuestion.options;

      let optionsText = '';

      options.map((option) => {
        optionsText += option.text + ', ';
      });

      return optionsText;
    },
    getSelectedImagesObject(){
      let selectedImages = this.selectedImages;
      let returnObject = [];
      this.images.map((image) => {
        if(selectedImages.indexOf(image.id) > -1)
        {
          returnObject.splice(selectedImages.indexOf(image.id), 0, image);
        }

      })
      return returnObject;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    removeSelectedImage(item) {
      const index = this.selectedImages.indexOf(item.id);
      if (index >= 0) this.selectedImages.splice(index, 1);
    },
    addedNewQuestion(question) {
      this.questions.push(question);
      this.addQuestionModalVisible = false;
    },
    getImages() {
      this.$axios.get("/images").then((res) => {
        this.images = res.data.data;
      });
    },
    getQuestions() {
      this.$axios.get("/pub/questions").then((res) => {
        this.questions = res.data.data;
      });
    },
    addNewProduct() {
      if (this.newProduct.name != null) {
        this.products.push(JSON.parse(JSON.stringify(this.newProduct)));
        this.newProduct.name = null;
        this.newProduct.quantity = 1;
        this.$refs.newProductName.focus();
      }
    },
    deleteProduct(index) {
      this.products.splice(index, 1);
    },
    addNewMail() {
      if (this.newMail.name != null && this.newMail.mail != null) {
        this.mails.push(JSON.parse(JSON.stringify(this.newMail)));
        this.newMail.name = null;
        this.newMail.mail = null;
        this.$refs.newMailName.focus();
      }
    },
    deleteMail(index) {
      this.mails.splice(index, 1);
    },
    drawRequest() {
      this.drawRequestLoading = true;
      let payload = {
        products: this.products,
        mails: this.mails,
        draw: this.draw,
        images: this.selectedImages
      };
      this.$axios
        .post("/draws", payload)
        .then((res) => {
          this.$toast.success("Çekiliş başarıyla kaydedildi");
          this.clearForm();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error(
            err?.response?.data?.message || "Beklenmedik Bir Hata Oluştu"
          );
        })
        .finally(() => {
          this.drawRequestLoading = false;
        });
    },
    clearForm() {
      this.newProduct = {
        name: null,
        quantity: 1,
      };
      this.newMail = {
        name: null,
        mail: null,
      };
      (this.mails = []),
        (this.products = []),
        (this.questions = []),
        (this.drawRequestLoading = false),
        (this.draw = {
          name: null,
          questionId: null,
        });
    },
  },
  created() {
    this.getQuestions();
    this.getImages();
  },
};
</script>
<style lang="" scoped></style>
