<template>
  <v-dialog v-model="visible" max-width="70%">
    <v-card>
      <v-card-title>Yeni Soru Ekle</v-card-title>
      <v-card-text>
        <v-text-field label="Soru" v-model="question.name"></v-text-field>
      </v-card-text>
      <v-card-text>
        <div
          v-for="(option, index) in options"
          :key="`options-${index}`"
          class="d-flex align-center"
        >
          <v-btn icon color="error" @click="deleteOption(index)"
            ><v-icon>mdi-minus-circle</v-icon></v-btn
          >
          <v-text-field
            label="Cevap"
            style="width: 200"
            v-model="option.text"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-text class="d-flex align-center">
        <v-btn icon color="success" @click="addOption" 
          ><v-icon>mdi-plus-circle</v-icon></v-btn
        >
        <v-text-field
          label="Cevap Ekle"
          style="width: 200"
          v-model="newOption.text"
          @keyup.enter="addOption()"

        ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn @click="saveQuestion" text color="success" :disabled="!(String(question.name).length > 0 && options.length > 0)"
          ><v-icon>mdi-content-save</v-icon>Kaydet</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: ["visibleModal"],
  data() {
    return {
      newOption: {
        text: null,
        inputType: "Checkbox",
      },
      question: {
        name: null,
      },
      options: [],
    };
  },
  computed: {
    visible: {
      get() {
        return this.visibleModal;
      },
      set(val) {
        this.$emit("visibleChange", val);
      },
    },
  },
  watch: {},
  mounted() {},
  methods: {
    addOption() {
      if (this.newOption.text != null) {
        this.options.push(JSON.parse(JSON.stringify(this.newOption)));
        this.newOption.text = null;
      }
    },
    deleteOption(index) {
      this.options.splice(index, 1);
    },
    saveQuestion(){
        this.addOption();
        let optionsBody = JSON.parse(JSON.stringify(this.options));
        let questionBody = JSON.parse(JSON.stringify(this.question));
        this.$axios.post('/draws/questions', {questionBody, optionsBody}).then((res) => {
            let question = res.data.data;
            this.$emit("newQuestion", question);

        })
    }
  },
};
</script>
<style lang="" scoped></style>
