<template>
  <div>
    <v-card>
      <v-card-subtitle>Yeni Logo Ekle</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12"> Ön izleme </v-col>
          <v-col cols="12">
            <v-app-bar>
              <div class="d-flex align-center">
                <v-img
                  :alt="newLogo.name"
                  class="shrink mr-6"
                  contain
                  :src="newLogo.url"
                  transition="scale-transition"
                  :width="newLogo.width"
                  :height="newLogo.height"
                />
              </div>
            </v-app-bar>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Logo Adı"
              v-model="newLogo.name"
              placeholder="Narbulut"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="URL"
              v-model="newLogo.url"
              placeholder="https://blade.narbulut.com/images/narbulut-logo-full.png"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="number"
              v-model="newLogo.width"
              label="Genişlik"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="number"
              v-model="newLogo.height"
              label="Yükseklik"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn text color="success" @click="saveNewLogo"
              ><v-icon left>mdi-content-save</v-icon>Kaydet</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider class="mt-6 mb-6"></v-divider>
    <div v-if="images.length > 0">
        <v-row v-for="(image, index) in images" :key="'image+' + index">
          <v-col cols="4" class="d-flex align-center">
            {{ image.name }}
          </v-col>
          <v-col cols="6">
            <v-img
              :alt="image.name"
              class="shrink ml-6"
              contain
              :src="image.url"
              transition="scale-transition"
              :width="250"
            />
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      images: [],
      newLogo: {
        name: null,
        width: 200,
        height: null,
        url: null,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    saveNewLogo() {
      this.$axios
        .post("/images", this.newLogo)
        .then((res) => {
          console.log(res);
          this.images.push(res.data.data);
          this.newLogo.name = null;
          this.newLogo.url = null;
          this.newLogo.height= null;
          this.newLogo.width = 200;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getImages() {
      this.$axios
        .get("/images")
        .then((res) => {
          this.images = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getImages();
  },
};
</script>
<style lang="" scoped></style>
