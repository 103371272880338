<template>
  <v-container fluid>
    <Register/>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Register from './Register.vue';

export default {
  name: 'Home',
  components: {
    Register
}
}
</script>
