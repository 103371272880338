<template>
  <v-container fluid>
    <v-row justify="center">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Yeni Çekiliş Ekle</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <NewDraw></NewDraw>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Mevcut Çekiliş Listesi</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <DrawList></DrawList>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>
<script>
import NewDraw from "../../components/NewDraw.vue";
import DrawList from "../../components/DrawList.vue";
export default {
  name: "",
  components: {
    NewDraw,
    DrawList,
  },
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    getDraws() {
      this.$axios.get("/draws").then((res) => {
        console.log(res);
      });
    },
  },
  created() {
    this.getDraws();
  },
};
</script>
<style lang="" scoped></style>
