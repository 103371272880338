import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
    guest: null,
    images: null,
    currentDraw: null,
    currentQuestion: null,
    loading: {
      draw: true,
    },
    drawId: null,
  },
  mutations: {
    SET_USER(state, {user, token}) {
      state.token = token;
      state.user = user;
    },
    SET_IMAGES(state, images)
    {
      state.images = images;
    },
    SET_QUESTION(state, question)
    {
      state.currentQuestion = question;
    },
    SET_GUEST(state, { guest, drawId }) {
      state.guest = guest;
      state.drawId = drawId;
    },
    SET_DRAW(state, draw) {
      state.currentDraw = draw;
      state.loading.draw = false;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  getters: {
    guestIsRegistered(state) {
      if (
        state.currentDraw != null &&
        state.drawId != null &&
        state.guest != null
      ) {
        return state.currentDraw?.id == state?.drawId && state.guest != null;
      } else {
        return false;
      }
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
